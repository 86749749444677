<template>
  <div id="cameraGroupSetting" v-loading="loading" :element-loading-text="$t('alarm.a0')">
         <!-- 页面标题 -->
        <div class="header-title">
          <span>{{ $t('video.viddata023') }}</span>
        </div>
        <div class="header">
            <div class="header-left">
              <div class="header-condition">
                <span>{{$t('video.viddata022') }}</span>
                <a-select v-model="cameraGroupId" style="width: 300px" @change="cameraGrChange">
                  <a-select-option
                      v-for="(itme, index) in cameraGroupList"
                      :key="index"
                      :value="itme.id"                      
                      >
                      {{ itme.name }}
                    </a-select-option>
                </a-select>
              </div>
            </div>
            <div>
               <div class="header-condition">
                  <a-button type="primary" style=""  @click="addGroup()"> {{ $t('video.viddata025') }} </a-button>
                  <a-button type="primary" style=""  @click="deleteGroup()" :disabled="!hasWritePermisson"> {{ $t('video.viddata026') }} </a-button>
              </div>
              
            </div>
        </div>
        <div class="header">
            <div class="header-left">
              <div class="header-condition">
                <span>{{$t('energy.enedata254') }}</span>
                <a-select v-model="siteId" style="width: 300px">
                  <a-select-option
                      v-for="(itme, index) in siteList"
                      :key="index"
                      :value="itme.no"
                      >
                      {{ itme.text }}
                    </a-select-option>
                </a-select>
              </div>
            </div>
            <div>
               <div class="header-condition">
                  <a-button type="primary" style=""  @click="editGroup()" :disabled="!hasWritePermisson"> {{ $t('video.viddata027') }} </a-button>
                  <a-button type="primary" style="margin-left:10px;"  @click="initPage()"> {{ $t('energy.enedata128') }} </a-button>
              </div>
            </div>
        </div>
        <div class="main" ref="main">
          <a-row>
              <a-col :span="11">
                  <div class="header-title">
                    <span>{{ $t('video.viddata020') }}</span>
                  </div>
                   <a-table
                      :row-selection="rowSelectionAdd"
                      :columns="columns"
                      :data-source="unRegCameraList"
                      :row-key="record => record.id" 
                      :pagination="pagination"
                      :scroll="tscroll"
                      size="small"
                   >
                   </a-table>
              </a-col>
              <a-col :span="2">
                  <div class="header-condition" style="width:100%;padding:10px;">
                    <a-button type="primary" style=""  @click="selectCamera()"> {{ $t('energy.enedata191') }} </a-button>
                    <a-button type="primary" style=""  @click="removeCamera()"> {{ $t('energy.enedata169') }} </a-button>
                </div>
              </a-col>
              <a-col :span="11">
                 <div class="header-title">
                  <span>{{ $t('video.viddata021') }}</span>
                </div>
                 <a-table
                    :row-selection="rowSelectionRe"
                    :columns="columns"
                    :data-source="regCameraList"
                    :row-key="record => record.id" 
                    :pagination="pagination"
                    :scroll="tscroll"
                    size="small"
                  >
                   </a-table>
              </a-col>
          </a-row>
          <a-row>
              <a-col style="margin-top:10px;text-align: right;">
                    <span style="color:red;" v-if="!hasWritePermisson">{{$t('video.viddata028')}}</span>
                    <a-button type="primary" style="" :disabled="!hasWritePermisson"  @click="save()"> {{ $t('energy.enedata023') }} </a-button>
                    <!-- <a-button type="primary" style=""  @click="batchEdit()" :disabled="!hasWritePermisson"> {{ $t('video.viddata029') }} </a-button> -->
              </a-col>
          </a-row> 
        </div>

        <a-modal v-model="visible" :title="name" @ok="handleOk">
          <div v-loading="frmloading" :element-loading-text="$t('alarm.a0')">
              <a-form-model ref="cameraGroupForm" 
                  :model="form" 
                  :rules="rules"
                  :label-col="formItemLayout.labelCol" 
                  :wrapper-col="formItemLayout.wrapperCol"  >
                      
                  <a-form-model-item  ref="name" prop="name">
                        <span slot="label">
                          {{$t('video.viddata022') }}&nbsp;
                          <a-tooltip :title='tooltip'>
                          <a-icon type="question-circle-o" />
                          </a-tooltip>
                      </span>
                      <a-input 
                          v-model="form.name"
                          @blur="
                          () => {
                              $refs.name.onFieldBlur();
                          }
                          "
                          :placeholder="inputLabel"
                      />
                  </a-form-model-item>
                      
              </a-form-model>
          </div>
        </a-modal>
  </div>
  
</template>
<script>
import { getCameraGroupList,getCameraGroupInfoList,getCameraInfoList,saveCameraGroupInfo,deleteCameraGroupInfo,updateCameraGroupInfoList } from "../../api/video";
// import { downloadUrl } from "../../utils/utils";
// import moment from "moment";



export default {
  data(){
      let name = this.$t("video.viddata022");
      let addTitle  = this.$t("energy.add") + name;
      let spLabel = this.$t("energy.setting.reportGr.spLabel").replace('${label}',name);
      let lenLabel = this.$t("energy.setting.reportGr.lenLabel").replace('${label}',name).replace('${min}',1).replace('${max}',50);
      let inputLabel = this.$t("energy.setting.reportGr.inputLabel");
      inputLabel = inputLabel.replace('${label}',name);
      let tooltip =this.$t("energy.setting.reportGr.stop")+' [ ` ~ ! @ # $ % ^ & *+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】';


      let normalName = (rule, value, callback) => {
          let regEn = /[`~!@#$%^&*+<>?:",\/;']/im;

          if(regEn.test(value)) {
              callback(new Error(spLabel));
          } else {
              callback();
          }
      };


      return {
          cameraGroupList:[],
          cameraGroupId:'',
          cameraGroupName:'',
          loading:false,
          tscroll:{x:300,y:280},
          columns:[
              {title: this.$t('video.viddata006')		 , dataIndex: 'name'                            ,width:200       }, 
          ],
          unRegCameraList:[],
          regCameraList:[],
          pagination:false,
          siteId:'',
          siteList:[],
          hasWritePermisson:false,
          visible:false,
          name:name,
          frmloading:false,
          formItemLayout:{
            labelCol: {
                      xs: { span: 24 },
                      sm: { span: 8 },
                  },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
          },
          formTailLayout:{
            labelCol: { span: 4 },
            wrapperCol: { span: 8, offset: 4 },
          },
          addTitle:addTitle,
          tooltip:tooltip,
          inputLabel:inputLabel,
          spLabel:spLabel,
          lenLabel:lenLabel,
          form:{
            id:0,
            name: "",
          },
          rules: {
              name: [
                  { required: true, message: inputLabel, trigger: 'blur' },
                  { min: 1, max: 30, message: lenLabel, trigger: 'blur' },
                  { validator:normalName, trigger: 'blur' },
              ]
          },
          addSelectedRowKeys:[],
          addSelectedRows:[],
          reSelectedRowKeys:[],
          reSelectedRows:[],
      }
  },
  computed: {
    rowSelectionAdd() {
        return { selectedRowKeys: this.addSelectedRowKeys, onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            this.addSelectedRows = selectedRows;
            this.addSelectedRowKeys=selectedRowKeys;
        } };
      
      
      
      
      // {
      //   addSelectedRowKeys,
      //   onChange: (selectedRowKeys, selectedRows) => {
      //     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      //     this.addSelectedRows = selectedRows;
      //     this.addSelectedRowKeys=selectedRowKeys;
      //   }
      // };
    },
    rowSelectionRe() {
        return { selectedRowKeys: this.reSelectedRowKeys, onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            this.reSelectedRows = selectedRows;
            this.reSelectedRowKeys=selectedRowKeys;
        } };
      // const { reSelectedRowKeys } = this;
      // return {
      //   reSelectedRowKeys,
      //   onChange: (selectedRowKeys, selectedRows) => {
      //     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      //     this.reSelectedRows   = selectedRows;
      //     this.reSelectedRowKeys =selectedRowKeys;
      //   }
      // };
    },
  },
  activated() {
    this.cameraGroupId = 0;
    this.siteId = this.$route.query.id+'';
    this.initPage();  
  },
  mounted() { 
    // 调用表格高度计算方法
    this.tableSize();
    // 根据窗口变化计算表格高度
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  methods:{
      getCameraGroupName(){
        for (let i = 0; i < this.cameraGroupList.length; i++) {
          const el = this.cameraGroupList[i];
          if(this.cameraGroupId*1 === el.id*1){
            return el.name;
          }
        }
      },
      initPage(){
          this.loading = true;
          getCameraGroupList(this.cameraGroupId,0,this.siteId)
            .then((res) => {
            console.log("res");
            console.log(res);
            if(res.errorCode==='00'){
                this.cameraGroupList = res.data.videoBaseBeanList;
                this.cameraGroupId = res.data.cameraGrId
                this.cameraGroupName = this.getCameraGroupName();
                this.siteList = res.data.siteList;
                this.unRegCameraList = res.data.videoCameraGroupBean.unRegisteredCameraList;
                this.regCameraList = res.data.videoCameraGroupBean.registeredCameraList;
                this.hasWritePermisson = res.data.videoCameraGroupBean.hasWritePermisson;
            }
            this.clearTableSelect();
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            this.loading = false;
        });
      },
      save(){
          let ids = new Array();
          for (let i = 0; i < this.regCameraList.length; i++) {
              const element = this.regCameraList[i];
              ids.push(element.id);
          }
          let param = {
              action:0,
              clientId: this.$store.getters.clientId,
              siteId: this.$route.query.id,
              sitegrId: this.$store.getters.sitegrId,
              tenantId: 0,
              camerasetId:this.cameraGroupId,
              cameraIds:ids,
          };

          this.$confirm({
              // title: "确定要进行设定内容注册吗?",
              title:this.$t("energy.enedata184"),
              centered: true,
              onOk: () => {
                  this.loading = true;
                  updateCameraGroupInfoList(param)
                  .then((res) => {
                      console.log("res");
                      console.log(res);
                      if(res.errorCode==='00'){
                          this.$message.info(res.msg);
                      }else{
                          this.$message.error(res.msg);
                      }
                      this.clearTableSelect();
                      this.loading = false;
                  }).catch((err) => {
                      console.log(err);
                      this.loading = false;
                  });
              }
          });
          
      },
      selectCamera(){
          // arr.splice(arr.indexOf(ele),length):
          console.log(' selectCamera selectedRows: ', this.addSelectedRows);
          if(this.regCameraList==null)
              this.regCameraList = new Array();
          if(this.addSelectedRows.length ==  this.unRegCameraList.length){
              for (let i = 0; i < this.addSelectedRows.length; i++) {
                const el = this.addSelectedRows[i];
                this.regCameraList.push(el);
              }
              // this.regCameraList = {...this.regCameraList,...this.addSelectedRows};
              this.unRegCameraList = [];
          }else{
            for (let i = 0; i < this.addSelectedRows.length; i++) {
              const el = this.addSelectedRows[i];
              this.regCameraList.push(el);
              this.unRegCameraList.splice(this.unRegCameraList.indexOf(el),1);
            }
          }
          this.unRegCameraList.sort();
          this.regCameraList.sort();
          this.addSelectedRows = [];
          this.addSelectedRowKeys = [];
      },
      removeCamera(){
          // arr.splice(arr.indexOf(ele),length):
          console.log(' removeCamera selectedRows: ', this.reSelectedRows);
          if(this.unRegCameraList==null)
              this.unRegCameraList = new Array();
          if(this.reSelectedRows.length ==  this.regCameraList.length){
               for (let i = 0; i < this.reSelectedRows.length; i++) {
                  const el = this.reSelectedRows[i];
                  this.unRegCameraList.push(el);
                }
              // this.unRegCameraList = {...this.regCameraList,...this.addSelectedRows};
              this.regCameraList = [];
          }else{
            for (let i = 0; i < this.reSelectedRows.length; i++) {
              const el = this.reSelectedRows[i];
              this.unRegCameraList.push(el);
              this.regCameraList.splice(this.regCameraList.indexOf(el),1);
            }
          }
          this.unRegCameraList.sort();
          this.regCameraList.sort();
          this.reSelectedRows = new Array();
          this.reSelectedRowKeys = new Array();
      },
      clearTableSelect(){
          this.addSelectedRows = new Array();
          this.addSelectedRowKeys = new Array();
          this.reSelectedRows = new Array();
          this.reSelectedRowKeys = new Array();
      },
      addGroup(){
          if(this.$refs.cameraGroupForm){
              this.$refs.cameraGroupForm.resetFields();
          }
          this.form.id = 0;
          this.form.name = "";
          this.visible = true;
      },
      editGroup(){
          if(this.$refs.cameraGroupForm){
              this.$refs.cameraGroupForm.resetFields();
          }
          this.form.id = this.cameraGroupId;
          this.form.name = this.cameraGroupName;
          this.visible = true;
      },
      deleteGroup(){
          this.$confirm({
              // title: "确定要进行设定内容注册吗?",
              title:this.$t("energy.enedata185"),
              centered: true,
              onOk: () => {
                  this.loading = true;
                 
                  let params =  {
                      action:0,
                      clientId: this.$store.getters.clientId,
                      siteId: this.$route.query.id,
                      sitegrId: this.$store.getters.sitegrId,
                      tenantId: 0,
                      cameraGrId  : this.cameraGroupId
                  };
                  deleteCameraGroupInfo(params)
                    .then((res) => {
                      console.log("res");
                      console.log(res);
                      if(res.errorCode==='00'){
                          this.$message.info(res.msg);  
                          this.removeCameraGroup( this.cameraGroupId);
                          this.cameraGroupId = this.cameraGroupList[0].id;
                          this.cameraGroupName = this.cameraGroupList[0].name;
                          this.cameraGrChange(this.cameraGroupId);
                          this.visible = false;
                          this.clearTableSelect();
                      }else{
                          this.$message.error(res.msg);
                      }
                      this.loading = false;
                  }).catch((err) => {
                      console.log(err);
                      this.loading = false;
                  });
              }
          });
      },
      removeCameraGroup(id){
         let tmpCGR = new Array();
         for (let i = 0; i < this.cameraGroupList.length; i++) {
            const el = this.cameraGroupList[i];
            if(el.id*1 === id*1){
              continue;
            }
            tmpCGR.push(el);
         }
         this.cameraGroupList = tmpCGR;
      },
      batchEdit(){

      },
      handleOk(){
          this.$refs.cameraGroupForm.validate(valid => {
              if (valid) {
                   this.$confirm({
                      // title: "确定要进行设定内容注册吗?",
                      title:this.$t("energy.enedata184"),
                      centered: true,
                      onOk: () => {
                          this.saveCameraGroup(this.form.id,this.form.name);
                      }
                  });
              } else {
                  console.log('error submit!!');
                  return false;
              }
          });
      },
      saveCameraGroup(id,name){
          let params =  {
                action:0,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                cameraGrId  : id,
                cameraGrName: name,
            };
            this.frmloading = true;
            saveCameraGroupInfo(params)
              .then((res) => {
                console.log("res");
                console.log(res);
                if(res.errorCode==='00'){
                    this.cameraGroupList.push(res.data.videoBean);
                    this.cameraGroupId = res.data.videoBean.id;
                    this.cameraGroupName = res.data.videoBean.name;
                    this.$message.info(res.msg);
                    if(id===0){
                        this.cameraGrChange(this.cameraGroupId);
                    }
                    this.visible = false;
                    this.clearTableSelect();
                }else{
                    this.$message.error(res.msg);
                }
                this.frmloading = false;
            }).catch((err) => {
                console.log(err);
                this.frmloading = false;
            });
      },
      cameraGrChange(e){
          console.log('cameraGrChange',e);
          this.cameraGroupName = this.getCameraGroupName();
          this.loading = true;
          this.clearTableSelect();
          getCameraGroupInfoList(this.siteId,e)
            .then((res) => {
              console.log("res");
              console.log(res);
              if(res.errorCode==='00'){
                this.unRegCameraList = res.data.unRegisteredCameraList;
                this.regCameraList = res.data.registeredCameraList;
                this.hasWritePermisson = res.data.hasWritePermisson;
              }
             
              this.loading = false;
          }).catch((err) => {
              console.log(err);
              this.loading = false;
          });
      },
      // 表格高度计算
      tableSize() {
          setTimeout(() => {
              let height = this.$refs.main.clientHeight;
              let width = this.$refs.main.clientWidth;
              let tableHeight = height - 160;
              this.tscroll.y = tableHeight;
              // this.tscroll.x = width;
              console.log(this.tscroll);
          }, 0);
      }
  }
}
</script>
<style scoped>

#cameraGroupSetting {
    width: 100%;
    height: 100%;
    padding: 20px;
    font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
.header-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;
}
.header-title::before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 22px;
    margin-right: 20px;
    background-color: #7682ce;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
}
.header-left {
    display: flex;
    justify-content: flex-start;
}
.header-condition {
    font-size: 14px;
    display: flex;
    flex-direction: column;
}
.header-condition button{
  margin-bottom: 5px;
}
.header-condition span{
  margin-bottom: 5px;
}
.main{
    width: 100%;
    height: calc(100% - 200px);
    box-shadow: 0 2px 15px #bdbcbc;
    padding: 10px;
  }
</style>